import styled from "@emotion/styled";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import React, { useRef } from "react";

import Accordion from "../components/Accordion";
import Awards from "../components/Awards";
import AwardsCarousel from "../components/AwardsCarousel";
import Button from "../components/Button";
import Colleagues from "../components/Colleagues";
import ContentWrapper from "../components/ContentWrapper";
import Heading from "../components/Heading";
import Hero from "../components/Hero/v7";
import Icon from "../components/Icon";
import Introduction from "../components/Introduction";
import Link from "../components/Link";
import ImageCarousel from "../components/Modular/ImageCarousel";
import PageHighlight from "../components/PageHighlight";
import Partners from "../components/Partners";
import Principles from "../components/Principles";
import SEO from "../components/SEO";
import TwoText from "../components/TwoText";
import VerticalMargin from "../components/VerticalMargin";
import VisuallyHidden from "../components/VisuallyHidden";
import useInViewport from "../hooks/useInViewport";
import theme from "../theme";
import { about, person } from "../types";
import mq from "../utils/mediaQuery";

const HideOnMobile = styled.div`
  display: none;
  ${mq("2")} {
    display: block;
  }
`;

const HideOnDesktop = styled.div`
  display: block;
  ${mq("2")} {
    display: none;
  }
`;

const HideOnMobile2Tablet = styled.div`
  display: none;
  ${mq("3")} {
    display: block;
  }
`;

const HideOnTablet2Desktop = styled.div`
  display: block;
  ${mq("3")} {
    display: none;
  }
`;

const DescriptionTwoText = styled(TwoText)`
  margin-bottom: ${theme.spacing("xxl")};
`;

const StyledVerticalMargin = styled(VerticalMargin)`
  margin-top: ${theme.spacing("xxl")};
  margin-bottom: ${theme.spacing("xxl")};
  ${mq("2")} {
    margin-top: ${theme.space("xxl")};
    margin-bottom: ${theme.spacing("xxl")};
  }

  ${mq("3")} {
    margin-top: ${theme.spacing("xxxxl")};
    margin-bottom: ${theme.spacing("xxxxl")};
  }
`;

const TeamTwoText = styled(TwoText)`
  margin-top: ${theme.spacing("xxxl")};
  margin-bottom: ${theme.spacing("xxl")};
`;

const About = ({
  data: {
    dato: { about, persons },
  },
}) => {
  const {
    seoMetaTags,
    title,
    thumbnail,
    heroVideo,
    tagline,
    descriptionIntro,
    descriptionBody,
    principlesTitle,
    principlesActionBody,
    principlesActionText,
    principlesActionPath,
    principles,
    pageHighlightTitle,
    pageHighlightActionPath,
    pageHighlightActionText,
    imageCarousel,
    teamIntro,
    teamBody,
    awardsTitle,
    awards,
    awardsBody,
    partnersTitle,
    partners,
    honorTitle,
    honorPartners,
  } = about;

  const formattedPrinciples = principles.map((principle) => ({
    ...principle,
    image: principle.image.url,
  }));

  const formattedAccordionItems = principles.map((principle) => ({
    heading: principle.title,
    body: principle.text,
    image: principle.image.url,
  }));

  const principlesCta = {
    label: principlesActionText,
    path: principlesActionPath,
    body: principlesActionBody,
  };

  const colleaguesRef = useRef();
  const colleaguesInView = useInViewport(colleaguesRef, "-15%");

  const highlightRef = useRef();
  const highlightInView = useInViewport(highlightRef, "-20%");

  return (
    <>
      <SEO tags={seoMetaTags} />

      <Hero
        title={title}
        image={thumbnail}
        video={heroVideo}
        introduction={
          <Introduction color={"text.light"}>{tagline}</Introduction>
        }
      />

      <ContentWrapper columns={12}>
        {(descriptionIntro || descriptionBody) && (
          <DescriptionTwoText
            introhtml
            intro={descriptionIntro}
            body={descriptionBody}
            html
          />
        )}
      </ContentWrapper>

      <ImageCarousel carouselImages={imageCarousel} />

      <HideOnMobile>
        <ContentWrapper columns={12}>
          <Principles
            title={principlesTitle}
            cta={principlesCta}
            items={formattedPrinciples}
          />
        </ContentWrapper>
      </HideOnMobile>

      <HideOnDesktop>
        <ContentWrapper>
          <Accordion
            selected={0}
            title={principlesTitle}
            items={formattedAccordionItems}
            showNumbers
          />
        </ContentWrapper>
      </HideOnDesktop>

      <HideOnDesktop>
        <StyledVerticalMargin>
          <ContentWrapper columns={8} ref={highlightRef}>
            <PageHighlight
              reveal={highlightInView}
              title={<Heading size={2}>{pageHighlightTitle}</Heading>}
              action={
                <Link to={`/${pageHighlightActionPath}`}>
                  <Button styling="link" size="large" as="span">
                    <span>{pageHighlightActionText}</span>
                    <Icon type="arrow" />
                  </Button>
                </Link>
              }
            />
          </ContentWrapper>
        </StyledVerticalMargin>
      </HideOnDesktop>

      <VerticalMargin>
        <ContentWrapper ref={colleaguesRef} columns="none" padding="0">
          <VisuallyHidden>
            <Heading size={2}>Ons team</Heading>
          </VisuallyHidden>
          <Colleagues people={persons} reveal={colleaguesInView} />
        </ContentWrapper>
      </VerticalMargin>

      <VerticalMargin>
        <ContentWrapper columns={12}>
          {(teamIntro || teamBody) && (
            <TeamTwoText introhtml intro={teamIntro} body={teamBody} html />
          )}
        </ContentWrapper>
      </VerticalMargin>

      <VerticalMargin>
        <HideOnMobile2Tablet>
          <Awards title={awardsTitle} items={awards} />
        </HideOnMobile2Tablet>
        <HideOnTablet2Desktop>
          <AwardsCarousel title={awardsTitle} items={awards} />
        </HideOnTablet2Desktop>
        {awardsBody && (
          <ContentWrapper columns={8}>
            <TeamTwoText introhtml body={awardsBody} html />
          </ContentWrapper>
        )}
        <ContentWrapper columns={12}>
          <Partners
            partnersTitle={partnersTitle}
            partners={partners}
            honorsTitle={honorTitle}
            honors={honorPartners}
          />
        </ContentWrapper>
      </VerticalMargin>
    </>
  );
};

About.propTypes = {
  data: PropTypes.shape({
    dato: PropTypes.shape({
      about: PropTypes.shape(about).isRequired,
      persons: PropTypes.arrayOf(PropTypes.shape(person)).isRequired,
    }).isRequired,
  }).isRequired,
};

export default About;

export const query = graphql`
  query AboutEnQuery {
    dato {
      about(locale: en) {
        seoMetaTags: _seoMetaTags {
          tag
          attributes
          content
        }
        title
        descriptionIntro
        descriptionBody
        tagline
        thumbnail {
          data: responsiveImage(
            imgixParams: { fit: crop, h: 710, fm: jpg, auto: format }
          ) {
            ...responsiveImageFragment
          }
        }
        heroVideo {
          ...MuxVideoFragment
        }
        imageCarousel {
          data: responsiveImage(
            imgixParams: { fit: crop, h: 520, w: 848, fm: jpg, auto: format }
          ) {
            ...responsiveImageFragment
          }
          title
        }
        pageHighlightTitle
        pageHighlightActionText
        pageHighlightActionPath
        teamIntro
        teamBody
        principlesTitle
        principlesActionBody
        principlesActionText
        principlesActionPath
        principles {
          id
          title
          text: content
          image {
            url
          }
          video {
            ...MuxVideoFragment
          }
        }
        awardsTitle
        awards {
          id
          title
          logo {
            title
            alt
            url
            data: responsiveImage(
              imgixParams: { fit: crop, w: 200, h: 95, auto: format }
            ) {
              ...responsiveImageFragment
            }
          }
          image {
            url
            data: responsiveImage(
              imgixParams: { fit: crop, w: 365, h: 245, auto: format }
            ) {
              ...responsiveImageFragment
            }
          }
        }
        awardsBody
        partnersTitle
        partners {
          id
          title
          logo {
            title
            alt
            url
            data: responsiveImage(
              imgixParams: { fit: clip, w: 136, h: 136, auto: format }
            ) {
              ...responsiveImageFragment
            }
          }
        }
        honorTitle
        honorPartners {
          id
          title
          logo {
            title
            alt
            url
            data: responsiveImage(
              imgixParams: { fit: clip, w: 136, h: 136, auto: format }
            ) {
              ...responsiveImageFragment
            }
          }
        }
      }
      persons: allPeople(orderBy: position_ASC, first: 100) {
        id
        firstName: firstname
        lastName: lastname
        jobTitle: title
        image: avatar {
          data: responsiveImage(
            imgixParams: { fit: crop, w: 205, h: 250, fm: jpg }
          ) {
            ...responsiveImageFragment
          }
        }
      }
    }
  }
`;
